import {
    convertMinutesToTime,
    isCodeInServices, isInBox,
    isStringValidInteger, isSystemCourier,
    parseTime,
    splitNames, waitingToDeliveryToBox,
    wasDelivery,
    wasPickUp
} from "../../utils";
import {isMine} from "../shipmentsHelper";

export const checkAdditionalConditions = additionalConditions => {
    let returnValue = true;
    Object.keys(additionalConditions).forEach(function (key, index) {
        if (!additionalConditions[key]) {
            returnValue = false;
        }
    });

    return returnValue;
};

export const isFormValidated = validationRules => {
    return Object.values(validationRules).reduce((accumulator, current) => accumulator && !current, true);
};

export const getDefaultPickUpCondition = (shipment) => {
    return {
        cash: shipment.Typ_Platby !== 2,
        packageCount: shipment.PocetKusov === 1,
    };
};

export const formatShipmentToExchangeMutation = (values, shipment) => {
    let data = {
        zasielkaKod: shipment.Zasielka_Kod,
        messengerCode: parseInt(values.messengerCode),
        street: values.street,
        streetNumber: values.streetNumber,
        city: values.city,
        zip: values.zip,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    return data;
};

export const formatShipmentToPickUpUpdateMutation = (values, shipment) => {
    const senderName = splitNames(values.nameSender);
    const deliveryName = splitNames(values.nameDelivery);

    let data = {
        zasielkaKod: shipment.Zasielka_Kod,
        dobaCakania: convertMinutesToTime(values.time),
        menoO: senderName[0] || '',
        priezviskoO: senderName[1] || '',
        pocetKusov: parseInt(values.amount),
        hmotnost: parseFloat(values.weight),
        hmotnostId: values.weightId === null ? null : parseInt(values.weightId),
        poznamkaIntern: values.noteIntern,
        poznamkaKlient: values.noteClient,
        menoD: deliveryName[0] || '',
        priezviskoD: deliveryName[1] || '',
        telefonD: values.phoneDelivery,
    };
    Object.keys(data).forEach((key) => ((data[key] == null || data[key] === '') && key !== 'hmotnostId' && key !== 'menoO' && key !== 'priezviskoO' && key !== 'menoD' && key !== 'priezviskoD') && delete data[key]);

    return data;
};

export const formatShipmentToPickUpMutation = (values, shipment, deliveryCode) => {
    const senderName = splitNames(values.nameSender);
    const deliveryName = splitNames(values.nameDelivery);

    let data = {
        zasielkaKod: shipment.Zasielka_Kod,
        dobaCakania: convertMinutesToTime(values.time),
        menoO: senderName[0] || '',
        priezviskoO: senderName[1] || '',
        pocetKusov: parseInt(values.amount),
        hmotnost: parseFloat(values.weight),
        hmotnostId: values.weightId === null ? null : parseInt(values.weightId),
        poznamkaIntern: values.noteIntern,
        poznamkaKlient: values.noteClient,
        menoD: deliveryName[0] || '',
        priezviskoD: deliveryName[1] || '',
        telefonD: values.phoneDelivery,
        deliveryCode: deliveryCode
    };
    Object.keys(data).forEach((key) => ((data[key] == null || data[key] === '') && key !== 'hmotnostId' && key !== 'menoO' && key !== 'priezviskoO' && key !== 'menoD' && key !== 'priezviskoD'  ) && delete data[key]);

    return data;
};

export const getPickUpValidationRules = values => {
    return {
        nameSender: (values.nameSender === null || values.nameSender.trim() === ""),
        nameDelivery: (values.nameDelivery === null || values.nameDelivery.trim() === ""),
        time: !isStringValidInteger(values.time) || parseInt(values.time) < 0,
        amount: !isStringValidInteger(values.amount) || parseInt(values.amount) <= 0,
        weight: isNaN(values.weight) && values.weightId === null,
    };
};

export const getExchangeValidationRules = values => {
    return {
        messengerCode: !isStringValidInteger(values.messengerCode) || (parseInt(values.messengerCode) < 0),
        street: (values.street.trim() === "" || values.street === null),
        streetNumber: (values.streetNumber.trim() === "" || values.streetNumber === null),
        city: (values.city.trim() === "" || values.city === null),
        zip: (values.zip.trim() === "" || values.zip === null),
    };
};

export const isExchangeFinished = (lastExchange) => {
    return lastExchange && (lastExchange?.Canceled || lastExchange?.Rejected || lastExchange?.Delivered);
};

export const getShipmentCodeForReturnSubShipmentFromBox = (customerIdentification) => {
    const customIdentificatorPrefix = 'bb';
    if (customerIdentification && customerIdentification.startsWith(customIdentificatorPrefix)) {
        return customerIdentification.substring(customIdentificatorPrefix.length);
    }

    return false;
};

export const getInitExchangeState = (shipment, userCode) => {
    let exchangeFinished = isExchangeFinished(shipment.LastExchange);

    return {
        messengerCode: shipment.LastExchange?.To.Kurier_Kod && !exchangeFinished ? String(shipment.LastExchange?.To.Kurier_Kod) : '464',
        street: shipment.LastExchange?.Street && !exchangeFinished ? shipment.LastExchange?.Street : 'Prievozska',
        streetNumber: shipment.LastExchange?.StreetNumber && !exchangeFinished ? shipment.LastExchange?.StreetNumber  : '34',
        city: shipment.LastExchange?.City && !exchangeFinished ? shipment.LastExchange?.City : 'Bratislava',
        zip: shipment.LastExchange?.Zip && !exchangeFinished ? shipment.LastExchange?.Zip : '82105',
        disabled: (exchangeFinished && shipment.Priradene === null) ||
            !wasPickUp(shipment) ||
            wasDelivery(shipment) ||
            shipment.Priradene === null ||
            !isMine(shipment, userCode) ||
            (shipment.LastExchange?.To.Kurier_Kod === userCode && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved) ||
            isInBox(shipment) ||
            waitingToDeliveryToBox(shipment)
        ,
    };
};

export const initPickUpState = (shipment, userCode) => {
    return {
        nameSender: shipment.MenoO + ' ' + shipment.PriezviskoO,
        amount: String(shipment.PocetKusov),
        time: shipment.DobaCakania === null || shipment.DobaCakania === '00:00:00' ? "0" : parseTime(shipment.DobaCakania).toString(),
        weight: shipment.Id_Hmotnost === null ? shipment.Hmotnost : null,
        weightId: shipment.Id_Hmotnost,
        nameDelivery: shipment.KontaktDoruc,
        phoneDelivery: shipment.TelefonD,
        noteIntern: shipment.PoznamkaIntern,
        noteClient: shipment.PoznamkaKlient,
        disabledForm: !(shipment.ZStav && shipment.ZStav_popis && (
            shipment.ZStav_popis.ZStav_Popis_Kod === 'INT' ||
            shipment.ZStav_popis.ZStav_Popis_Kod === 'ORD' ||
            (shipment.ZStav.ZStav_Kod === 'IN' && (
                shipment.ZStav_popis.ZStav_Popis_Kod === 'IPF' ||
                (shipment.ZStav_popis.ZStav_Popis_Kod === 'IRW' && shipment.DatumPrevz === null)
            )) ||
            (shipment.ZStav.ZStav_Kod === 'PU' && (
                shipment.ZStav_popis.ZStav_Popis_Kod === 'DM' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'CIF' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'COF' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'CAC' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'PGC'
            )))) ||
            shipment.Prevzal !== null  ||
            shipment.Priradene === null ||
            shipment.InternPickUp,
        disabledSend: (!shipment.InternPickUp && !(shipment.ZStav && shipment.ZStav_popis && (
            shipment.ZStav_popis.ZStav_Popis_Kod === 'INT' ||
            shipment.ZStav_popis.ZStav_Popis_Kod === 'ORD' ||
            (shipment.ZStav.ZStav_Kod === 'IN' && (
                shipment.ZStav_popis.ZStav_Popis_Kod === 'IPF' ||
                (shipment.ZStav_popis.ZStav_Popis_Kod === 'IRW' && shipment.DatumPrevz === null)
            )) ||
            (shipment.ZStav.ZStav_Kod === 'PU' && (
                shipment.ZStav_popis.ZStav_Popis_Kod === 'DM' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'CIF' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'COF' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'CAC' ||
                shipment.ZStav_popis.ZStav_Popis_Kod === 'PGC'
            ))))) ||
            (shipment.Prevzal !== null && !shipment.InternPickUp) ||
            shipment.Priradene === null ||
            isSystemCourier(shipment) && !isMine(shipment, userCode),
    };
};
