import React from "react";
import AboutUser from "../aboutUser";
import ShippingSelector from "../shippingSelector";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {Link, Router} from "@gatsbyjs/reach-router";
import RefreshButton from "../RefreshButton";
import CodSummary from "../codSummaryTable/codSummary";
import LogoutButton from "./logout-button";
import RoutePlanningButton from "./routePlanningButton";
import {makeStyles} from "@material-ui/core/styles";
import SmenyButton from "./SmenyButton";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import Loading from "../loading";
import Button from "@material-ui/core/Button";
import FontSize from "../fontSize";

const LOCAL_ALL = loader('../../gql/query/client/all.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    leftDrawer: {
        padding: '15px',
    },
    toolbar: theme.mixins.toolbar,
}));

export default function LeftMenu({handleReloadClick}) {
    const classes = useStyles();
    const {data: dataLocal, loading: loadingLocal} = useQuery(LOCAL_ALL);

    if (loadingLocal) {
        return (<Loading/>)
    }

    return (
        <div className={`${classes.root} ${classes.leftDrawer}`}>
            <div>
                <AboutUser />
                <ShippingSelector/>
                <Divider/>
                <FontSize/>
                <Grid container spacing={6} justifyContent="center">
                    <Grid item xs={12}>
                        <Router primary={false}>
                            <RefreshButton path="/" handleClick={handleReloadClick} />
                        </Router>
                    </Grid>
                    <Grid item xs={12}>
                        <CodSummary/>
                    </Grid>
                    {/*show button only to margala, kroslak and kovar courier*/}
                    {/*{dataLocal && [29,13,191].includes(dataLocal.user.kod) && (*/}
                        <Grid item xs={12}>
                            <RoutePlanningButton/>
                        </Grid>
                    {/* )} */}
                    <Grid item xs={12}>
                        <Link to="/shifts">
                            <Button
                                variant="contained"
                                size="medium"
                                fullWidth
                            >
                                smeny
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <SmenyButton/>
                    </Grid>
                    <Grid item xs={12}>
                        <LogoutButton/>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.toolbar}/>
        </div>
    );
}
