import React, {Fragment, useState} from 'react';
import styled from '@emotion/styled';
import {css} from 'emotion'
import {navigate} from '@gatsbyjs/reach-router';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from "@material-ui/core/Typography";
import {
    wasDelivery,
    wasPickUp,
    wasAssign,
    wasPickUpByMe,
    waitingToDeliveryToBox,
    isSystemCourierRaw
} from "../../utils";
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SwipeableViews from 'react-swipeable-views';
import StyledP from '../shipments/styledP';
import A from '../shipments/a';
import Wrapper from '../shipments/wrapper';
import PhoneBox from "../shipments/phoneBox";
import {isMine} from "../../pages/shipmentsHelper";
import {loader} from "graphql.macro";
import {useQuery} from "@apollo/client";
import {errorMessage} from "../../utils/messages";
import {useSnackbar} from "notistack";
import ScrollManager from "../../utils/ScrollManager";
import ShipmentFlags from "../shipments/ShipmentFlags";
import useLongPress from "../../utils/react/hooks/useLongPress";
import TimeSlot from "../shipment/timeSlot";
import SmsTime from "../shipment/smsTime";
import SmsTimeWrapper from "../shipment/smsTimeWrapper";
import {generateDeliveryMapUrl} from "./utils";
import DeliveryData from "../shippingPoint/deliveryData";
import PickUpData from "../shippingPoint/pickUpData";

const ABOUT_USER = loader('../../gql/query/client/all.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    normal: {
        backgroundColor: theme.colors.greenDomca,
    },
    icon: {
        fontSize: '1em',
        verticalAlign: 'middle',
    },
    companyLine: {
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
    },
    small: {
        fontSize: '0.85em',
        margin: 0,
    },
    detailIcon: {
        fontSize: '2.5em',
        marginBottom: '-7px',
    },
    alert: {
        color: theme.colors.redDomca,
        fontWeight: "bold",
        fontSize: 10,
    },
    noteAlert: {
        fontSize: '2em',
    },
    swipeHeader: {
        width: 230,
    },
    selected: {
        border: '5px solid red',
    },
    disableClick: {
        '&': {
            pointerEvents: 'none'
        },
    },
    done: {
        color: theme.colors.done,
    },
    street: {
        display: 'inline-block',
        marginRight: 0,
        textTransform: 'lowercase',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    line: {
        clear: 'both',
    },
    flag: {
        float: 'right',
        marginRight: 65,
    },
    hrefWithoutTime: {
        maxWidth: 'calc(100% - 100px)',
    },
    hrefWithTime: {
        maxWidth: 'calc(100% - 140px)',
    },
    alignRight: {
        textAlign: 'right',
        marginRight: 49,
    },
    infoLineWrapper: {
        display: 'flex',
        width: '100%',
    },
    smsTime: {
        whiteSpace: 'nowrap',
        marginLeft: 'auto',
        fontSize: 10,
    },
    timeSlot: {},
    big: {
        '& $timeSlot': {
            fontSize: 14,
        },
        '& $smsTime': {
            fontSize: 14,
        },
        '& $companyLine': {
            fontSize: 19,
        },
    },
}));

const ShippingTitle = ({shipment, multiSelect, isSelected, handleMultiSelectShipment}) => {
    const classes = useStyles();
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const [index, setIndex] = useState(1);
    const {data: dataLocal} = useQuery(ABOUT_USER, {
        onError: error => errorMessage('Chyba pri nacitavani: ' + error, enqueueSnackbar, closeSnackbar),
    });

    const isExpress = shipment.Flags.includes('e');
    let isMineVar = isMine(shipment, dataLocal.user.kod);
    let wasPickedUp = wasPickUp(shipment);
    let wasPickedUpByMe = wasPickUpByMe(shipment, dataLocal.user.kod);
    let wasDelivered = wasDelivery(shipment);
    let wasAssigned = wasAssign(shipment) && (!isSystemCourierRaw(shipment?.Priradene?.Kurier_Kod) || isSystemCourierRaw(dataLocal.user.kod));  // isSystemCourier(shipment?.Priradene?.Kurier_Kod)
    const isWaitingToDeliveryToBox = waitingToDeliveryToBox(shipment);

    const handleSwipe = (index) => {
        setIndex(index);
        if (index === 2) {
            let url;
            if (wasDelivered) {
                url = '/shipment/' + shipment.Zasielka_Kod;
            } else if (isWaitingToDeliveryToBox) {
                url = '/shipment/' + shipment.Zasielka_Kod + '/deliveryBB';
            } else if (shipment.LastExchange?.From.Kurier_Kod === dataLocal.user.kod && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved && !shipment.LastExchange?.Delivered) {
                url = '/shipment/' + shipment.Zasielka_Kod + '/exchange';
            } else if (wasPickedUp) {
                url = '/shipment/' + shipment.Zasielka_Kod + '/delivery';
            } else if (wasAssigned) {
                url = '/shipment/' + shipment.Zasielka_Kod + '/pickup';
            } else {
                url = '/shipment/' + shipment.Zasielka_Kod;
            }
            navigate(url);
        }
    };

    const handleMultiSelectClick = event => {
        if (multiSelect && typeof event.target.parentNode.dataset.detailLink === "undefined") {
            handleMultiSelectShipment(shipment.Zasielka_Kod);
        }
    };

    const onPhoneClick = () => {
        setIndex(1);
    }


    let additionalInfo = [];
    if (shipment.AreaDelivery) {
        additionalInfo.push('zona: ' + shipment.AreaDelivery.Label);
    }
    shipment.Flags.forEach(flag => {
        additionalInfo.push((
            <span key={flag}>{flag}</span>)
        );
    });
    if (shipment.Dobierka != null && shipment.Dobierka && shipment.Dobierka.Amount) {
        additionalInfo.push((
            <span className={classes.alert}>
                {shipment.Dobierka.Amount}{shipment.Dobierka.Currency && (shipment.Dobierka.Currency)}
            </span>
        ));
    }

    if (shipment.PoznamkaKlient2) {
        additionalInfo.push(shipment.PoznamkaKlient2);
    }

    if (shipment.ETADelivery) {
        additionalInfo.push((<DeliveryData shipment={shipment}/>));
    }

    if (shipment.ETAPickUp) {
        additionalInfo.push((<PickUpData shipment={shipment}/>));
    }

    const onLongPress = (event) => {
        event.preventDefault();
        event.stopPropagation();

        handleMultiSelectShipment(shipment.Zasielka_Kod);
    };

    const onClick = () => {
        navigate('/shipment/' + shipment.Zasielka_Kod);
    };

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    let firstTitle;
    if (waitingToDeliveryToBox(shipment)) {
        firstTitle = <StyledP className={classes.companyLine}>{shipment.LastActiveCreateReservation?.box?.company?.name} - {shipment.LastActiveCreateReservation?.box.name}<TimeSlot shipment={shipment} /></StyledP>
    } else {
        firstTitle = <StyledP className={classes.companyLine}>{shipment.NazovD}<TimeSlot className={classes.timeSlot} shipment={shipment} /></StyledP>
    }

    let swipeableRightText;
    if (wasDelivered) {
        swipeableRightText = 'Info';
    } else if (isWaitingToDeliveryToBox) {
        swipeableRightText = 'Doručiť BB';
    } else if ((shipment.LastExchange?.From.Kurier_Kod === dataLocal.user.kod && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved && !shipment.LastExchange?.Delivered) ||
        (shipment.LastExchange?.To.Kurier_Kod === dataLocal.user.kod && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved && !shipment.LastExchange?.Delivered)) {
        swipeableRightText = 'Prelozit';
    } else if (wasPickedUp) {
        swipeableRightText = 'Doručiť';
    } else if (wasAssigned) {
        swipeableRightText = 'Vyzdvihnúť';
    } else {
        swipeableRightText = 'Info';
    }

    return (
        <Fragment>
            <Wrapper isSelected={multiSelect && isSelected}>
                <ScrollManager scrollKey={shipment.Zasielka_Kod} />
                <div className={clsx(dataLocal.settings.fontSize === 'big' && classes.big)}>
                    <ShipmentFlags showPickup={isMineVar && wasPickedUpByMe && !wasDelivered} showMine={isMineVar && !wasPickedUpByMe} showExpress={isExpress} />
                    <SwipeableViews
                        onChangeIndex={handleSwipe}
                        enableMouseEvents={true}
                        style={{overflow: 'hidden'}}
                        //slideStyle={{padding: '10px 30px', boxSizing: 'border-box'}}
                        index={index}
                    >
                        <div style={{overflow: 'hidden'}}>
                            <PhoneBox phoneSender={shipment.TelefonO} phoneConsignor={shipment.TelefonD} onPhoneClick={onPhoneClick}/>
                        </div>
                        <div style={{position: 'relative', overflow: 'hidden', minHeight: '49px'}} onClick={multiSelect ? handleMultiSelectClick : undefined}>
                            {firstTitle}
                            <StyledP className={clsx(classes.line, wasDelivered && classes.done)}>
                                {wasDelivered ? (
                                    <CheckSharpIcon className={classes.icon}/>
                                ) : (
                                    <ArrowForwardSharpIcon className={classes.icon}/>
                                )}
                                {((shipment.UlicaD && shipment.UlicaD.length > 0) || (shipment.ObecD && shipment.ObecD.length > 0) || (shipment.CisloD && shipment.CisloD.length > 0)) && (
                                    <A className={clsx(classes.hrefWithTime,wasDelivered && classes.done, multiSelect && classes.disableClick)} href={generateDeliveryMapUrl(shipment)}>
                                        <b><div className={classes.street}>{shipment.UlicaD} {shipment.CisloD}</div></b>
                                    </A>
                                )}
                            </StyledP>
                            <StyledP className={clsx(classes.line)}>
                                {shipment.PayerCompany.Nazov}
                            </StyledP>

                            {waitingToDeliveryToBox(shipment) && shipment.LastActiveCreateReservation && (
                                <StyledP className={clsx(classes.line)}>
                                    <ArrowForwardSharpIcon className={classes.icon}/>
                                    <A className={clsx(classes.hrefWithoutTime, multiSelect && classes.disableClick)} href={"https://maps.google.com/?q=" + encodeURI(shipment.LastActiveCreateReservation.box.location.latitude + ',' + shipment.LastActiveCreateReservation.box.location.longitude)}>
                                        <b><div className={classes.street}>{shipment.LastActiveCreateReservation.box.street}</div></b>
                                        {/*<span> {shipment.LastActiveCreateReservation.box.name}</span>*/}
                                    </A>
                                </StyledP>
                            )}

                            <StyledLink {...longPressEvent}  data-detail-link="true">
                                {(shipment.PoznamkaIntern || shipment.PoznamkaKlient) &&
                                <span className={clsx(classes.alert, classes.noteAlert)}>!</span>}
                                <ArrowForwardIosIcon className={classes.detailIcon}/>
                            </StyledLink>
                            {/*<StyledP className={smaller}>{stav}</StyledP>*/}
                            {/*<StyledP className={smaller}>{shipment.ShippingTimeGroup} {shipment.ShippingTimeName} {shipment.ShippingTime} <span>{shipment.Flags.join(',')}</span></StyledP>*/}
                            <div className={classes.infoLineWrapper}>
                                <p className={classes.small}>
                                    {
                                        additionalInfo.map((info, index) => (
                                            <Fragment key={index}>{info}, </Fragment>
                                            ))
                                    }
                                </p>
                                <SmsTimeWrapper shipment={shipment}>
                                    <div className={classes.smsTime}>SMS: <SmsTime shipment={shipment}/></div>
                                </SmsTimeWrapper>
                            </div>
                        </div>
                        <div>
                            <Typography variant="h3" className={classes.swipeHeader}>
                                {swipeableRightText}
                            </Typography>
                        </div>
                    </SwipeableViews>
                </div>
            </Wrapper>
        </Fragment>
    );
};

export const cardClassName = css({
    borderRadius: 7,
    color: 'black',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

export const smaller = css({
    fontSize: 10,
});

const StyledLink = styled("div")(cardClassName, {
    '&:hover': {
        color: 'grey',
    },
    display: 'inline-block',
    color: 'black',
    zIndex: '1',
    padding: '1em',
    margin: '-1em',
    textDecoration: 'none',
    position: 'absolute',
    top: 14,
    right: 16,
});

export default ShippingTitle;
