import React, {Fragment, useReducer} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import {useMutation, makeVar, useReactiveVar} from '@apollo/client';
import {Loading} from '../../../components/index';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {errorMessage, successMessage} from "../../../utils/messages";
import {isSaturday, isSunday} from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import {navigate} from "@gatsbyjs/reach-router";
import {
    checkAdditionalConditions, getShipmentCodeForReturnSubShipmentFromBox,
    isFormValidated,
} from "../utils";
import {
    getDefaultDeliveryCondition,
    formatShipmentToDeliveryUpdateMutation,
    getDeliveryValidationRules,
    getInitDeliveryState,
    formatShipmentToDeliveryMutation,
} from './utils';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/sk";
import useStyles from "./styles";
import failStatus from "./failStatus";
import successStatus from "./successStatus";
import actions from "./actions";
import reducer from "./reducer";
import FormLabel from "@material-ui/core/FormLabel";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {addWorkingDays, isHoliday} from "../../../utils/datetime/utils";
import disabledWindowSlots from "./disabledWindowSlots";
import copy from "copy-to-clipboard";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import SmsHref from "../../../components/shipment/infoTab/smsHref";
import SmsIcon from "@material-ui/icons/Sms";
import SmsSelect from "../../../components/shipment/infoTab/smsSelect";
import DeliveryCheck from "../common/deliveryCheck";
import {isMine} from "../../shipmentsHelper";
import ShowBox from "../../../components/deliveryBBtab/showBox";

const DELIVERY = loader('../../../gql/mutations/deliveryShipment.graphql');
const DELIVERY_UPDATE_DATA = loader('../../../gql/mutations/updateDeliveryShipmentData.graphql');

const referenceVar = makeVar(null);

export default function Index({shipment, dataLocal, callCallbackForSend}) {
    const referenceNumber = useReactiveVar(referenceVar);
    const [state, dispatch] = useReducer(reducer, undefined, () => {
        return {
            values: getInitDeliveryState(shipment, dataLocal?.user?.kod),
            openFailDeliveryModal: false,
            openDeliveryAdditional: false,
            openDateModal: false,
            openEditClientNoteModal: false,
            openSuccessDeliveryAdditional: false,
            referenceNumber: null,
            //additionalConditions: {},
            additionalConditions: getDefaultDeliveryCondition(shipment),
            canSend: false,
            ...disabledWindowSlots(addWorkingDays(new Date(), 1)),
        };
    });

    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [sendDelivery, {loading}] = useMutation(DELIVERY, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne uložené.', enqueueSnackbar, closeSnackbar);
            navigate('/');
        },
    });
    const [sendDeliveryUpdate] = useMutation(DELIVERY_UPDATE_DATA, {
        onError: (error) => errorMessage('Chyba pri aktualizaci: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Aktualizovane.', enqueueSnackbar, closeSnackbar);
        },
    });

    // React.useEffect(() => {
    //     if (Object.keys(state.additionalConditions).length === 0 && state.additionalConditions.constructor === Object) { // if additionalConditions is empty object
    //         setState(prevState => {
    //             return {...prevState, additionalConditions: getDefaultDeliveryCondition(shipment)}
    //         });
    //     }
    // }, [shipment]);

    const handleClickOpenFailDeliveryModal = () => {
        dispatch({
            type: actions.openFailDelivery,
        });
    };

    const handleCloseDeliveryModal = () => {
        dispatch({
            type: actions.closeFailDelivery,
        });
    };

    const handleCloseAdditional = () => {
        dispatch({
            type: actions.closeAdditionalModal,
        });
    };

    const handleHelp = () => {
        dispatch({
            type: actions.helpClickModal,
        });
    };

    const handleChange = prop => event => {
        dispatch({
            type: actions.handleInputText,
            field: prop,
            payload: event.target.value,
        });
    };

    const handleChangePinCode = event => {
        dispatch({
            type: actions.handlePinCodeInput,
            payload: event.target.value,
        });
    };

    const handleAdditionalTextInfo = event => {
        dispatch({
            type: actions.handleAdditionalTextInfo,
            payload: event.target.value,
        });
    };

    const handleFocus = (event) => event.target.select();

    const handleSuccessButtonClick = () => {
        dispatch({
            type: actions.successDeliveryButtonClick,
        });
    };

    const handleCloseSuccessDeliveryAdditional = () => {
        dispatch({
            type: actions.closeSuccessDeliveryAdditionalModal,
        });
    }

    const handleSaveChanges = (event) => {
        const data = formatShipmentToDeliveryUpdateMutation(state.values, shipment);
        sendDeliveryUpdate({
            variables: data,
        });
    };

    const handleClickOkDeliveryModal = () => {
        dispatch({
            type: actions.setDeliveryCode,
            payload: state.values.deliveryCode,
        });
    };

    const handleCloseEditClientNoteModal = () => {
        dispatch({
            type: actions.closeEditClientNoteModal,
        });
    }

    const handleOkEditClientNoteModal = () => {
        dispatch({
            type: actions.yesClickEditClientNoteModal,
        });
    }

    const handleCustomerNoteOnBlur = (event) => {
        dispatch({
            type: actions.openEditClientNoteModal,
        });
    }

    const handleClickOkModal = () => {
        dispatch({
            type: actions.okClickModal,
        });
    };

    const handleClickOkDateModal = () => {
        dispatch({
            type: actions.okClickDateModal,
        });
    };

    const handleSubmit = () => {
        const data = formatShipmentToDeliveryMutation(state.values, shipment);
        if (typeof callCallbackForSend === 'function') {
            callCallbackForSend(data);
            return;
        }

        sendDelivery({
            variables: data,
        });
        dispatch({
            type: actions.sent,
        });
    };

    const handleCloseDateModal = () => {
        dispatch({
            type: actions.closeDateModal,
        });
    };

    const changeDateModal = (newDate) => {
        dispatch({
            type: actions.changeDateModal,
            payload: newDate,
        });
    };

    const handleDeliveryTimeSlot = (value) => {
        dispatch({
            type: actions.setDeliveryTimeSlot,
            payload: value.target.value,
        });
    }

    const formatReferenceNumber = (referenceNumber) => {
        if (referenceNumber !== null) {
            const parts = referenceNumber.match(/.{1,2}/g);
            return parts.join(" ");
        }
        return '';
    }

    const validationRules = getDeliveryValidationRules(state.values);
    // because lazyQuery on multiselect not update shipment after change cache we store it after mutation to state, if is cod reference loaded before use it instead dynamic loading it
    let referenceNumberLocal = shipment.Dobierka.ReferenceNumber !== null ? shipment.Dobierka.ReferenceNumber : (state.referenceNumber !== null ? state.referenceNumber : (referenceNumber !== null && typeof referenceNumber[shipment.Zasielka_Kod] !== "undefined" ? referenceNumber[shipment.Zasielka_Kod] : null));
    const referenceNumberFormatted = formatReferenceNumber(referenceNumberLocal);
    const handleCopyVSClick = () => {
        copy(referenceNumberLocal);
        successMessage('VS skopírovaný do schránky.', enqueueSnackbar, closeSnackbar);
    }
    const onlineValue = <Fragment>cez terminal
        {referenceNumberFormatted !== '' && <Fragment>, variabilny symbol:
        <span className={classes.referenceNumber}>{referenceNumberFormatted}</span>
        <Button
            onClick={handleCopyVSClick}
            variant="contained"
            startIcon={<FileCopyOutlinedIcon/>}
            className={classes.copyButton}
        >
            Kopírovať
        </Button>
        </Fragment>
        }
    </Fragment>;

    React.useEffect(() => {
        if (state.canSend) {
            handleSubmit();
        }
    }, [state.canSend]);


    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {loading && <Loading/>}
            <Dialog open={state.openFailDeliveryModal} onClose={handleCloseDeliveryModal}>
                <DialogTitle id="form-dialog-title">Dôvod neúspešného doručenia</DialogTitle>
                <DialogContent>
                    <FormControl required className={classes.selectField}>
                        <InputLabel id="delivery-label">Stav</InputLabel>
                        <Select
                            value={state.values.deliveryCode}
                            disabled={state.values.disabled}
                            onChange={handleChange('deliveryCode')}
                            autoWidth
                        >
                            {failStatus.map((item) => <MenuItem value={item.code}
                                                                key={item.code}>{item.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeliveryModal}>
                        Zrušiť
                    </Button>
                    <Button variant="contained" onClick={handleClickOkDeliveryModal}>
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={state.openDeliveryAdditional} onClose={handleCloseAdditional}>
                <DialogTitle id="form-dialog-title">Doplnkové informácie k nedoručeniu</DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label={state.values.deliveryCode === 'INE' ? 'Zadajte poznámku k dôvodu nedoručenia' : state.values.deliveryCode === 'WA' ? 'Zadajte novú adresu doručenia' : 'Poznámka'}
                        margin="dense"
                        multiline
                        maxRows="6"
                        defaultValue={state.values.additionalInfo.text}
                        onChange={handleAdditionalTextInfo}
                        disabled={state.values.disabled}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseAdditional}>
                        Zrušiť
                    </Button>
                    {state.values.deliveryCode === 'WA' && (
                        <Button variant="contained" onClick={handleHelp}>
                            <HelpOutlineIcon/> Neviem adresu
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleClickOkModal}>
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={state.openSuccessDeliveryAdditional} onClose={handleCloseSuccessDeliveryAdditional}>
                <DialogTitle id="form-dialog-title">Doplnkové informácie k doručeniu</DialogTitle>
                <DialogContent>
                    <FormControl required className={classes.selectField}>
                        <InputLabel id="delivery-label">Typ doručenia</InputLabel>
                        <Select
                            value={state.values.deliveryCode}
                            disabled={state.values.disabled}
                            onChange={handleChange('deliveryCode')}
                            autoWidth
                        >
                            {successStatus.map((item) => <MenuItem value={item.code}
                                                                   key={item.code}>{item.title}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Poznámka k doručeniu"
                        margin="dense"
                        multiline
                        maxRows="6"
                        defaultValue={state.values.additionalInfo.text}
                        onChange={handleAdditionalTextInfo}
                        disabled={state.values.disabled}
                        error={validationRules.additionalInfoText}
                        helperText={validationRules.additionalInfoText ? "pri tomto type doručenia musí byť vyplnená poznámka" : null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseSuccessDeliveryAdditional}>
                        Zrušiť
                    </Button>
                    <Button variant="contained" onClick={handleClickOkDeliveryModal} disabled={validationRules.additionalInfoText}>
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={state.openDateModal} onClose={handleCloseDateModal} classes={{paper: classes.datePickerDialogPaper}}>
                <DialogTitle id="form-dialog-title">
                    Kedy chce zákazník doručiť?
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
                        <DatePicker
                            ToolbarComponent={() => null}
                            autoOk
                            variant="static"
                            disablePast
                            value={state.values.additionalInfo.date}
                            onChange={changeDateModal}
                            shouldDisableDate={(date) => { return isHoliday(date) || isSaturday(date) || isSunday(date)}}
                        />
                    </MuiPickersUtilsProvider>
                    <FormControl component="fieldset" margin="none" className={classes.slotBox}>
                        <FormLabel component="legend">Slot:</FormLabel>
                        <RadioGroup
                            aria-label="gender"
                            name="radio-buttons-group"
                            defaultValue="TSA0Z"
                            value={state.values.additionalInfo.slot}
                            onChange={handleDeliveryTimeSlot}
                        >
                            <FormControlLabel value="TSA0Z" control={<Radio />} label="neurčený čas 8-21h" disabled={state.disabledWindowSlot0} />
                            <FormControlLabel value="TSA1Z" control={<Radio />} label="doobeda 8-12h" disabled={state.disabledWindowSlot1} />
                            <FormControlLabel value="TSA2Z" control={<Radio />} label="poobede 12:30-16:30h" disabled={state.disabledWindowSlot2} />
                            <FormControlLabel value="TSA3Z" control={<Radio />} label="vecer 17-21h" disabled={state.disabledWindowSlot3} />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" onClick={handleCloseDateModal}>
                        Zrušiť
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClickOkDateModal}
                        disabled={state.values.additionalInfo.slot === null}
                    >
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={state.openEditClientNoteModal} onClose={handleCloseEditClientNoteModal} classes={{paper: classes.datePickerDialogPaper}}>
                <DialogTitle id="form-dialog-title">
                    Naozaj chcete zmeniť klientsku poznámku z "{state.values.noteClientBeforeEdit}" na "{state.values.noteClient}" ?
                </DialogTitle>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" onClick={handleCloseEditClientNoteModal}>
                        Nie
                    </Button>
                    <Button variant="contained" onClick={handleOkEditClientNoteModal}>
                        Ano
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={0}>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={handleClickOpenFailDeliveryModal}
                        classes={{
                            root: classes.buttonFail,
                            label: classes.iconButtonLabel,
                        }}
                        disabled={state.values.disabled}
                    >
                        <MailOutlineIcon className={classes.icons}/>
                        <div className={classes.buttonLabel}>Nedoručiť</div>
                    </IconButton>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <Button onClick={handleSaveChanges} variant="contained"
                            disabled={state.values.disabled || !isFormValidated(validationRules)}>
                        Uložiť zmeny
                    </Button>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={handleSuccessButtonClick}
                        disabled={state.values.disabled || !checkAdditionalConditions(state.additionalConditions) || !isFormValidated(validationRules) || (state.values.pinRequired && (state.values.pinCode === null || state.values.pinCode.trim() === ""))}
                        classes={{
                            root: classes.buttonSuccess,
                            label: classes.iconButtonLabel,
                        }}
                    >
                        <MailOutlineIcon className={classes.icons}/>
                        <div className={classes.buttonLabel}>Doručiť</div>
                    </IconButton>
                </Grid>

                <DeliveryCheck shipment={shipment} state={state} dispatch={dispatch} />

                {getShipmentCodeForReturnSubShipmentFromBox(shipment.customerIdentification) && isMine(shipment, dataLocal?.user?.kod) && shipment.LastActiveCreateReservation && (
                    <ShowBox box={shipment.LastActiveCreateReservation?.box} showPin={true} />
                )}

                {shipment.TelefonD && shipment.TelefonD.length > 0 && (
                    <Grid item xs={12}>
                        {shipment.TelefonD}
                        <a href={"tel:"+shipment.TelefonD} className={classes.iconHref}><PhoneInTalk className={classes.icons}/></a>
                        <SmsHref
                            to={shipment.TelefonD}
                            isDelivery={true}
                            className={classes.iconHref}
                            shipment={shipment}
                        >
                            <SmsIcon className={classes.icons}/>
                        </SmsHref>
                        <SmsSelect shipment={shipment}/>
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Doba čakania"
                        margin="dense"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">min</InputAdornment>,
                        }}
                        defaultValue={state.values.time}
                        onChange={handleChange('time')}
                        onFocus={handleFocus}
                        disabled={state.values.disabled}
                        error={validationRules.time}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        required
                        label="Meno prijímateľa"
                        margin="dense"
                        defaultValue={state.values.nameDelivery}
                        onChange={handleChange('nameDelivery')}
                        onFocus={handleFocus}
                        disabled={state.values.disabled}
                        error={validationRules.nameDelivery}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Telefón prijímateľa"
                        margin="dense"
                        defaultValue={state.values.phoneDelivery}
                        onChange={handleChange('phoneDelivery')}
                        onFocus={handleFocus}
                        disabled={state.values.disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Interná poznámka"
                        margin="dense"
                        multiline
                        maxRows="6"
                        defaultValue={state.values.noteIntern}
                        onChange={handleChange('noteIntern')}
                        disabled={state.values.disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        fullWidth
                        label="Klientska poznámka"
                        margin="dense"
                        multiline
                        maxRows="6"
                        //defaultValue={state.values.noteClient}
                        value={state.values.noteClient}
                        onChange={handleChange('noteClient')}
                        disabled={state.values.disabled}
                        inputProps={{
                            onBlur: handleCustomerNoteOnBlur,
                        }}
                    />
                </Grid>

                {shipment.PinRequired && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            label="Pin kod z SMS"
                            margin="dense"
                            maxRows="6"
                            defaultValue={state.values.pinCode}
                            onChange={handleChangePinCode}
                            onFocus={handleFocus}
                            disabled={state.values.disabled}
                            error={state.values.pinRequired && (state.values.pinCode === null || state.values.pinCode.trim() === "")}
                            inputProps={{
                                inputmode: "numeric"
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </form>
    );
}
