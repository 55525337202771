import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import {useMutation, useQuery} from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Loading} from '../../components/index';
import { useSnackbar } from 'notistack';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { loader } from 'graphql.macro';
import CheckboxBlock from "../../components/shipment/checkboxBlock";
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Line from "../../components/shipment/line";
import Label from "../../components/shipment/label";
import Value from "../../components/shipment/value";
import {format} from "date-fns";
import {navigate, useMatch} from "@gatsbyjs/reach-router";
import {errorMessage, successMessage} from "../../utils/messages";
import {
    checkAdditionalConditions,
    isFormValidated,
    initPickUpState,
    formatShipmentToPickUpMutation,
    getPickUpValidationRules,
    getDefaultPickUpCondition,
    formatShipmentToPickUpUpdateMutation,
} from "./utils";
import ConditionWrapper from "../../components/shipment/conditionWrapper";
import {isMine} from "../shipmentsHelper";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import SmsHref from "../../components/shipment/infoTab/smsHref";
import SmsIcon from "@material-ui/icons/Sms";
import SmsSelect from "../../components/shipment/infoTab/smsSelect";

const GET_WEIGHTS = loader('../../gql/query/weights.graphql');
const PICKUP = loader('../../gql/mutations/pickUpShipment.graphql');
const PICKUP_UPDATE_DATA = loader('../../gql/mutations/updatePickUpShipmentData.graphql');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
    },
    selectField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
        minWidth: 220,
    },
    buttonSuccess: {
        color: theme.colors.greenDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    buttonFail: {
        color: theme.colors.redDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    icons: {
        fontSize: '1.5em',
    },
    buttonLabel: {
        fontSize: '0.6em',
        flexBasis: '100%',
    },
    iconButtonLabel: {
        flexWrap: 'wrap',
    },
    icons: {
        fontSize: 25,
    },
    iconHref: {
        color: theme.colors.href,
        display: 'inline-block',
        position: 'relative',
        zIndex: '1',
        padding: '1em 1em',
        margin: '-1em 0',
    },
}));

const statusList = [
    //{ code: 'PGC', title: 'Prevzate v poriadku',},
    { code: 'UNP', title: 'Nepripravené',},
    { code: 'DM', title: 'Nevhodne na prepravu',},
    { code: 'COF', title: 'Nepodarilo sa spojit',},
    { code: 'CAC', title: 'Zrusene po prichode kuriera',},
    { code: 'PA',  title: 'Neprítomný',},
    { code: 'PWA', title: 'Zlá adresa',},
    { code: 'PCD', title: 'Zmena termínu',},
];

export default function PickUpTab({shipment, dataLocal, callCallbackForSend}) {
    let initValue = initPickUpState(shipment, dataLocal?.user?.kod);
    const isScannerUrl = useMatch('/scanner/*');
    const isScannerNoteUrl = useMatch('/scannernote/*');
    const {loading: loadingWeight, data} = useQuery(
        GET_WEIGHTS,
        {
            variables: {Id_Skupina_Sluzba: shipment.Id_Skupina_Sluzba},
            onError: (error) => errorMessage('Chyba pri načítavaní vah. ' + error, enqueueSnackbar, closeSnackbar),
        }
    );
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const classes = useStyles();
    const [sendPickUp, {loading}] = useMutation(PICKUP, {
        onError: (error) => errorMessage('Chyba pri odosielaní: '+error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne uložené.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl || isScannerNoteUrl) {
                navigate('/scanner');
            } else {
                navigate('/');
            }
        },
    });
    const [sendPickUpUpdate] = useMutation(PICKUP_UPDATE_DATA, {
        onError: (error) => errorMessage('Chyba pri aktualizovani: '+error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Aktualizovane', enqueueSnackbar, closeSnackbar);
        },
    });
    const [values, setValues] = React.useState(initValue);
    const [deliveryCode, setDeliveryCode] = React.useState('COF');
    const [open, setOpen] = React.useState(false);
    const [additionalConditions, setAdditionalConditions] = React.useState({});
    React.useEffect(() => {
        if (initValue !== values) {
            setValues(initValue);
        }

        setAdditionalConditions(getDefaultPickUpCondition(shipment));
    }, [shipment]);
    React.useEffect(() => {
        if (data !== undefined && values.weightId !== null && values.weight === null ) { // || data.weights.filter(function( obj ) { return obj.Id_Hmotnost == shipment.Id_Hmotnost; })[0].Nazov !== values.weight)
            let weight;
            weight = data.weights.filter(function( obj ) {
                return obj.Id_Hmotnost === shipment.Id_Hmotnost;
            })[0];
            if (weight != null) {
                setValues({...values, 'weightId': weight.Id_Hmotnost, 'weight': weight.Nazov});
            }
        }
    }, [data, values.weightId]);

    const defaultAdditionalCondition = getDefaultPickUpCondition(shipment);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const validationRules = getPickUpValidationRules(values);

    const handleAdditionalConditionChange = prop => value => {
        setAdditionalConditions({...additionalConditions, [prop]: value});
    };
    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleDeliveryCode = (event) => {
        setDeliveryCode(event.target.value);
    };
    const handleSaveChanges = (event) => {
        const data = formatShipmentToPickUpUpdateMutation(values, shipment);
        sendPickUpUpdate({
            variables: data,
        });
    };
    const handleFocus = (event) => event.target.select();
    const handleWeightList = (event, value) => {
        if (typeof value === 'object' && value !== null) {
            setValues({...values, 'weightId': value.Id_Hmotnost, 'weight': value.Nazov});
        }
    };

    const handleWeightInput = (event, value, reason) => {
        if (reason === 'input') {
            setValues({...values, 'weightId': null, 'weight': value});
        } else if (reason === 'clear') {
            setValues({...values, 'weightId': null, 'weight': null});
        }
    };

    const handleSubmit = deliveryCode => () => {
        handleClose(true);
        const data = formatShipmentToPickUpMutation(values, shipment, deliveryCode);
        if (typeof callCallbackForSend === 'function') {
            callCallbackForSend(data);
            return;
        }
        sendPickUp({
            variables: data,
        });
    };

    let weights = (data && data.weights) || [];

    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {(loading || loadingWeight) && <Loading/>}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Dôvod neúspešného vyzdvihnutia</DialogTitle>
                <DialogContent>
                    <FormControl required className={classes.selectField}>
                        <InputLabel id="delivery-label">Stav</InputLabel>
                        <Select
                            labelId="delivery-label"
                            value={deliveryCode}
                            onChange={handleDeliveryCode}
                            autoWidth
                        >
                            {statusList.map((item) => <MenuItem value={item.code} key={item.code}>{item.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        Zrušiť
                    </Button>
                    <Button variant="contained" onClick={handleSubmit(deliveryCode)}>
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={0}>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={handleClickOpen}
                        classes={{
                            root: classes.buttonFail,
                            label: classes.iconButtonLabel,
                        }}
                        disabled={values.disabledForm || !checkAdditionalConditions(additionalConditions)}
                    >
                        <MailOutlineIcon className={classes.icons}/>
                        <div className={classes.buttonLabel}>Nevyzdvihnúť</div>
                    </IconButton>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <Button onClick={handleSaveChanges} variant="contained"
                                disabled={values.disabledForm || !isFormValidated(validationRules) || !isMine(shipment, dataLocal?.user?.kod)}>
                        Uložiť zmeny
                    </Button>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={handleSubmit('PGC')}
                        disabled={values.disabledSend || !checkAdditionalConditions(additionalConditions) || !isFormValidated(validationRules)}
                        classes={{
                            root: classes.buttonSuccess,
                            label: classes.iconButtonLabel,
                        }}
                    >
                        <MailOutlineIcon className={classes.icons}/>
                        <div className={classes.buttonLabel}>Vyzdvihnúť</div>
                    </IconButton>
                </Grid>
                {shipment.NazovO && shipment.NazovO.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Line>
                            {shipment.NazovO.length > 0 && (<span>firma: <b>{shipment.NazovO}</b></span>)}
                        </Line>
                        {shipment.DatumPrevz !== null && shipment.Prevzal &&  (
                            <Line>
                                <Label>vyzdvihnuté:</Label><Value>{format(shipment.DatumPrevz, 'd.M.y H:mm')} ({shipment.Prevzal.Nazov})</Value>
                            </Line>
                        )}
                    </Grid>
                )}

                {!defaultAdditionalCondition.cash && !values.disabledSend && (
                    <Grid item xs={12}>
                        <ConditionWrapper valid={additionalConditions.cash}>
                            <CheckboxBlock
                                label="Platba v hotovostni, nie na faktúru. Skontroluj od koho máš vybrat peniaze."
                                name="cash" onChange={handleAdditionalConditionChange('cash')}/>
                        </ConditionWrapper>
                    </Grid>
                )}

                {!defaultAdditionalCondition.packageCount && !values.disabledSend && (
                    <Grid item xs={12}>
                        <ConditionWrapper valid={additionalConditions.packageCount}>
                            <CheckboxBlock
                                label={"Zobral som všetky balíky v počte " + shipment.PocetKusov + " ks."}
                                name="packageCount" onChange={handleAdditionalConditionChange('packageCount')}/>
                        </ConditionWrapper>
                    </Grid>
                )}

                {shipment.TelefonO.length > 0 && (
                    <Grid item xs={12}>
                        {shipment.TelefonO}
                        <a href={"tel:"+shipment.TelefonO} className={classes.iconHref}><PhoneInTalk className={classes.icons}/></a>
                        <SmsHref
                            to={shipment.TelefonO}
                            isDelivery={false}
                            className={classes.iconHref}
                            shipment={shipment}
                        >
                            <SmsIcon className={classes.icons}/>
                        </SmsHref>
                        <SmsSelect shipment={shipment} isPickup={true}/>
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        label="Meno odosielateľa"
                        margin="dense"
                        fullWidth
                        required
                        defaultValue={values.nameSender}
                        onChange={handleChange('nameSender')}
                        onFocus={handleFocus}
                        disabled={values.disabledForm}
                        error={validationRules.nameSender}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Meno prijímateľa"
                        margin="dense"
                        defaultValue={values.nameDelivery}
                        onChange={handleChange('nameDelivery')}
                        onFocus={handleFocus}
                        disabled={values.disabledForm}
                        error={validationRules.nameDelivery}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Telefón prijímateľa"
                        margin="dense"
                        defaultValue={values.phoneDelivery}
                        onChange={handleChange('phoneDelivery')}
                        onFocus={handleFocus}
                        disabled={values.disabledForm}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Doba čakania"
                        margin="dense"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">min</InputAdornment>,
                        }}
                        defaultValue={values.time}
                        onChange={handleChange('time')}
                        onFocus={handleFocus}
                        disabled={values.disabledForm}
                        error={validationRules.time}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Počet kusov"
                        margin="dense"
                        defaultValue={values.amount}
                        onChange={handleChange('amount')}
                        onFocus={handleFocus}
                        disabled={values.disabledForm || shipment.Rozvoz}
                        error={validationRules.amount}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={weights}
                        classes={{
                            option: classes.option,
                        }}
                        freeSolo
                        autoHighlight
                        getOptionLabel={option => {
                            if (typeof option === 'object' && option !== null && option.hasOwnProperty('Nazov')) {
                                return option.Nazov;
                            } else {
                                return option;
                            }
                        }}
                        renderOption={option => (
                            <React.Fragment>
                                {option.Nazov}
                            </React.Fragment>
                        )}
                        value={values.weight}
                        onChange={handleWeightList}
                        onInputChange={handleWeightInput}
                        renderInput={params => (
                            <TextField
                                {...params}
                                className={classes.textField}
                                label="Hmotnosť (kg)"
                                fullWidth
                                margin="dense"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled',
                                }}
                                error={validationRules.weight}
                                disabled={values.disabledForm}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Interná poznámka"
                        margin="dense"
                        multiline
                        maxRows="6"
                        defaultValue={values.noteIntern}
                        onChange={handleChange('noteIntern')}
                        disabled={values.disabledForm}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Klientska poznámka"
                        margin="dense"
                        multiline
                        maxRows="6"
                        defaultValue={values.noteClient}
                        onChange={handleChange('noteClient')}
                        disabled={values.disabledForm}
                    />
                </Grid>
            </Grid>
        </form>
    );
}
